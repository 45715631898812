import "./Projects.css";
import projects from "../data/projects";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useIsMobile from "../hooks/useIsMobileDevice";
import useViewportWidth from "../hooks/useViewportWidth";

const coverImages = [
  "01_elwood_house.jpg",
  "06_beaumaris_unit_2.jpg",
  "08_brighton_townhouses.jpg",
  "07_alphington_house.jpg",
  "20_bentleigh_east_townhouses_1_3.jpg",
  // "18_beaumaris_unit_2_2.jpg",
  "22_browns_townhouses.jpg",
  "19_stali_canteen.jpg",
  "04_caulfield_north_house.gif",
  "02_mount_martha_house.jpg",
  "21_tony_tan_cooking_school.jpg",
  "09_beaumaris_unit_1.jpg",
  "17_red_hill_house.jpg",
  "11_caulfield_north_house.jpg",
  "15_trentham_house.jpg",
  "05_bentleigh_east_townhouses_1.jpg",
  "03_tyabb_house.jpg",
  "14_obrien_real_estate.jpg",
  "12_elwood_house_2.jpg",
  "13_excelsior_building.jpg",
  // '16_bentleigh_east_townhouses_2.jpg',
  "16_santana_bar.jpg",
  "10_bentleigh_east_townhouses_1_2.jpg",
];

const mobileCoverImages = [
  "01_elwood_house.jpg",
  "04_caulfield_north_house.gif",
  "05_bentleigh_east_townhouses_1.jpg",
  // "16_bentleigh_east_townhouses_2.jpg",
  // "20_bentleigh_east_townhouses_1_3.jpg",
  // "10_bentleigh_east_townhouses_1_2.jpg",
  "06_beaumaris_unit_2.jpg",
  "02_mount_martha_house.jpg",
  "03_tyabb_house.jpg",
  "14_obrien_real_estate.jpg",
  "07_alphington_house.jpg",
  "16_santana_bar.jpg",
  "13_excelsior_building.jpg",
  "08_brighton_townhouses.jpg",
  "21_tony_tan_cooking_school.jpg",
  "09_beaumaris_unit_1.jpg",
  "17_red_hill_house.jpg",
  "15_trentham_house.jpg",
  "19_stali_canteen.jpg",
  "22_browns_townhouses.jpg",
  // "18_beaumaris_unit_2_2.jpg",
  // "11_caulfield_north_house.jpg",
  // "12_elwood_house_2.jpg",
];

const smallImages = [
  "01_elwood_house.jpg",
  "02_mount_martha_house.jpg",
  // '03_tyabb_house.jpg',
  // '04_caulfield_north_house.gif',
  // '05_bentleigh_east_townhouses_1.jpg',
  // '06_beaumaris_unit_2.jpg',
  "22_browns_townhouses.jpg",
  "07_alphington_house.jpg",
  // '08_brighton_townhouses.jpg',
  // '09_beaumaris_unit_1.jpg',
  "10_bentleigh_east_townhouses_1_2.jpg",
  "11_caulfield_north_house.jpg",
  // '12_elwood_house_2.jpg',
  "13_excelsior_building.jpg",
  "14_obrien_real_estate.jpg",
  "15_trentham_house.jpg",
  // '16_bentleigh_east_townhouses_2.jpg',
  "16_santana_bar.jpg",
  // '17_red_hill_house.jpg',
  "18_beaumaris_unit_2_2.jpg",
  "19_stali_canteen.jpg",
  // '20_bentleigh_east_townhouses_1_3.jpg',
  "21_tony_tan_cooking_school.jpg",
];

const mobileSmallImages = [
  "01_elwood_house.jpg",
  "02_mount_martha_house.jpg",
  // '03_tyabb_house.jpg',
  // '04_caulfield_north_house.gif',
  // '05_bentleigh_east_townhouses_1.jpg',
  // '06_beaumaris_unit_2.jpg',
  // "22_browns_townhouses.jpg",
  "07_alphington_house.jpg",
  // '08_brighton_townhouses.jpg',
  "09_beaumaris_unit_1.jpg",
  "10_bentleigh_east_townhouses_1_2.jpg",
  "11_caulfield_north_house.jpg",
  // '12_elwood_house_2.jpg',
  "13_excelsior_building.jpg",
  // "14_obrien_real_estate.jpg",
  "15_trentham_house.jpg",
  // '16_bentleigh_east_townhouses_2.jpg',
  "16_santana_bar.jpg",
  // '17_red_hill_house.jpg',
  "18_beaumaris_unit_2_2.jpg",
  "19_stali_canteen.jpg",
  // '20_bentleigh_east_townhouses_1_3.jpg',
  "21_tony_tan_cooking_school.jpg",
];

const skewRight = ["14_obrien_real_estate.jpg"];
const skewMiddle = ["22_browns_townhouses.jpg"];

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedImage, setSelectedImage] = useState(0);
  const isMobile = useIsMobile();
  const windowWidth = useViewportWidth();

  useEffect(() => {
    setSelectedImage(0);
  }, [selectedProject]);

  const selectNextImage = () => {
    if (selectedProject?.images.length > selectedImage + 1) {
      setSelectedImage(selectedImage + 1);
    } else {
      setSelectedImage(0);
    }
  };

  const selectPrevImage = () => {
    if (selectedImage === 0) {
      setSelectedImage(selectedProject.images.length - 1);
    } else {
      setSelectedImage(selectedImage - 1);
    }
  };

  if (selectedProject && isMobile) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  useEffect(() => {
    isMobile && selectedImage && setTimeout(scrollToProjectInfo, 100);
    isMobile && selectedProject && setTimeout(scrollToProjectInfo, 100);
  }, [isMobile, selectedProject, selectedImage]);

  const scrollToProjectInfo = () => {
    ref.current.scrollIntoView({ top: 0, behavior: "smooth" });
  };
  const ref = useRef(null);
  return (
    <>
      <motion.div
        key={selectedProject?.path}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className={`selected-project-container ${
          selectedProject ? "show" : ""
        }`}
      >
        <div ref={ref} className="selected-project-section">
          {isMobile || windowWidth < 950 ? (
            <div className="project-info">
              <div>
                <div className="project-title">
                  {selectedProject?.title}, {selectedProject?.stage}
                </div>
                <div className="close-column">
                  <div
                    className="close-button"
                    onClick={() => setSelectedProject(null)}
                  >
                    {"><"}
                  </div>
                </div>
              </div>
              <div>
                <div className="slideshow">
                  <div
                    onClick={selectNextImage}
                    style={{
                      cursor:
                        selectedProject?.images.length > 1
                          ? "pointer"
                          : "default",
                    }}
                    className="image-preview"
                  >
                    <motion.img
                      key={selectedProject?.images[selectedImage]}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1 }}
                      src={`/project_images/${selectedProject?.path}/${selectedProject?.images[selectedImage]}`}
                      alt={selectedProject?.images[selectedImage]}
                    />
                  </div>
                  <div className="arrow-control">
                    <div className="back-arrow" onClick={selectPrevImage}>
                      {selectedProject?.images.length > 1 ? "<" : ""}
                    </div>
                    <div className="forward-arrow" onClick={selectNextImage}>
                      {selectedProject?.images.length > 1 ? ">" : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="project-description">
                  {selectedProject?.description}
                </div>
                {selectedProject?.awards && (
                  <div className="project-award-container">
                    {selectedProject?.awards.map(award => (
                      <div className="project-award">{award}</div>
                    ))}
                  </div>
                )}
                <div className="project-data">
                  {selectedProject?.type && (
                    <>
                      <div className="project-data-label">Type:</div>
                      <div className="project-data-value">
                        {selectedProject?.type}
                      </div>
                    </>
                  )}
                  {selectedProject?.architecture && (
                    <>
                      <div className="project-data-label">Architecture:</div>
                      <div className="project-data-value">
                        {selectedProject?.architecture}
                      </div>
                    </>
                  )}
                  {selectedProject?.interiorDesign && (
                    <>
                      <div className="project-data-label">Interior Design:</div>
                      <div className="project-data-value">
                        {selectedProject?.interiorDesign}
                      </div>
                    </>
                  )}
                  {selectedProject?.builder && (
                    <>
                      <div className="project-data-label">Builder:</div>
                      <div className="project-data-value">
                        {selectedProject?.builder}
                      </div>
                    </>
                  )}
                  {selectedProject?.photography && (
                    <>
                      <div className="project-data-label">Photography:</div>
                      <div className="project-data-value">
                        {selectedProject?.photography}
                      </div>
                    </>
                  )}
                  {selectedProject?.styling && (
                    <>
                      <div className="project-data-label">Styling:</div>
                      <div className="project-data-value">
                        {selectedProject?.styling}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="project-info">
              <div className="project">
                <div className="project-title">
                  {selectedProject?.title}, {selectedProject?.stage}
                </div>
                <div className="project-description">
                  {selectedProject?.description}
                </div>
                {selectedProject?.awards && (
                  <div className="project-award-container">
                    {selectedProject?.awards.map(award => (
                      <div className="project-award">{award}</div>
                    ))}
                  </div>
                )}
                <div className="project-data">
                  {selectedProject?.type && (
                    <>
                      <div className="project-data-label">Type:</div>
                      <div className="project-data-value">
                        {selectedProject?.type}
                      </div>
                    </>
                  )}
                  {selectedProject?.architecture && (
                    <>
                      <div className="project-data-label">Architecture:</div>
                      <div className="project-data-value">
                        {selectedProject?.architecture}
                      </div>
                    </>
                  )}
                  {selectedProject?.interiorDesign && (
                    <>
                      <div className="project-data-label">Interior Design:</div>
                      <div className="project-data-value">
                        {selectedProject?.interiorDesign}
                      </div>
                    </>
                  )}
                  {selectedProject?.builder && (
                    <>
                      <div className="project-data-label">Builder:</div>
                      <div className="project-data-value">
                        {selectedProject?.builder}
                      </div>
                    </>
                  )}
                  {selectedProject?.photography && (
                    <>
                      <div className="project-data-label">Photography:</div>
                      <div className="project-data-value">
                        {selectedProject?.photography}
                      </div>
                    </>
                  )}
                  {selectedProject?.styling && (
                    <>
                      <div className="project-data-label">Styling:</div>
                      <div className="project-data-value">
                        {selectedProject?.styling}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="slideshow">
                <div className="back-arrow" onClick={selectPrevImage}>
                  {selectedProject?.images.length > 1 ? "<" : ""}
                </div>
                <div
                  onClick={selectNextImage}
                  style={{
                    cursor:
                      selectedProject?.images.length > 1
                        ? "pointer"
                        : "default",
                  }}
                  className="image-preview"
                >
                  <motion.img
                    key={selectedProject?.images[selectedImage]}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    src={`/project_images/${selectedProject?.path}/${selectedProject?.images[selectedImage]}`}
                    alt={selectedProject?.images[selectedImage]}
                  />
                </div>
                <div className="forward-arrow" onClick={selectNextImage}>
                  {selectedProject?.images.length > 1 ? ">" : ""}
                </div>
              </div>
              <div className="close-column">
                <div
                  className="close-button"
                  onClick={() => setSelectedProject(null)}
                >
                  {"><"}
                </div>
              </div>
            </div>
          )}
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        style={selectedProject && isMobile ? { overflow: "hidden" } : {}}
        className="grid-container"
      >
        {(isMobile ? mobileCoverImages : coverImages).map(img => {
          const project = projects.find(p => img.includes(p.path));
          return (
            <ImgTile
              img={img}
              middle={skewMiddle.includes(img)}
              right={skewRight.includes(img)}
              onClick={() => setSelectedProject(project)}
              isSmall={(isMobile ? mobileSmallImages : smallImages).includes(
                img
              )}
              title={project.title}
            />
          );
        })}
      </motion.div>
    </>
  );
};

export default Projects;

const ImgTile = ({
  img,
  onClick,
  isSmall,
  title,
  right = false,
  middle = false,
}) => {
  let backgroundPosition = "bottom";
  if (right) {
    backgroundPosition = "left 60% bottom 0%";
  } else if (middle) {
    backgroundPosition = "center";
  }
  const StyledDiv = styled.div`
    &::before {
      content: "";
      background-image: url("/cover_images/${img}");
      background-size: cover;
      background-position: ${backgroundPosition};
      height: 100%;
      width: 100%;
      border-radius: 4px;
      transition: 0.3s;
    }
  `;
  return (
    <StyledDiv
      key={img}
      onClick={onClick}
      className={`grid-item ${isSmall ? "small" : ""}`}
    >
      <div className={`project-text`}>{title}</div>
    </StyledDiv>
  );
};
