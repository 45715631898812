import { useState, useEffect } from "react"

export default function useIsPC() {
  const [isPC, setIsPC] = useState(true)
  useEffect(() => {
    if (navigator.userAgent.indexOf('Mac OS X') !== -1) {
      setIsPC(false)
    }
  }, [])
  return isPC
}
