export const projects = [
  {
    path: "elwood_house",
    title: "Elwood House",
    stage: "2022",
    description:
      "Reinvigorating the classic bones of a double-fronted Victorian home, adding a modest addition at the back of the existing residence to connect with the north-facing garden.",
    images: [
      "elwood_house_01.jpg",
      "elwood_house_02.jpg",
      "elwood_house_03.jpg",
      "elwood_house_04.jpg",
      "elwood_house_05.jpg",
      "elwood_house_06.jpg",
      "elwood_house_07.jpg",
      "elwood_house_08.jpg",
      "elwood_house_09.jpg",
      "elwood_house_10.jpg",
      "elwood_house_11.jpg",
      "elwood_house_12.jpg",
      "elwood_house_13.jpg",
      "elwood_house_14.jpg",
    ],
    type: "Renovation & Addition",
    architecture: "Studio Martin",
    interiorDesign: "Studio Martin",
    builder: "Bluline Projects",
    photography: "Tess Kelly",
    styling: "Jarvis Barker",
  },
  {
    path: "mount_martha_house",
    title: "Mount Martha House",
    stage: "Concept Stage",
    description: "A coastal family home.",
    images: ["mount_martha_house_01.jpg"],
    type: "New Build",
    architecture: "Studio Martin",
    interiorDesign: "Malli Studio",
    builder: null,
    photography: null,
    styling: null,
  },
  {
    path: "browns_townhouses",
    title: "Browns Townhouses",
    stage: "2023",
    description:
      "A new build consisting of two four-bedroom dwellings with swimming pools. Offering a refreshing life style change for our clients. These Townhouses were designed to be minimal maintenance, modern and fresh with an attention to detail.",
    images: [
      "browns_townhouses_01.jpg",
      "browns_townhouses_02.jpg",
      "browns_townhouses_03.jpg",
      "browns_townhouses_04.jpg",
      "browns_townhouses_05.jpg",
      "browns_townhouses_06.jpg",
      "browns_townhouses_07.jpg",
    ],
    type: "New Build",
    architecture: "Studio Martin",
    interiorDesign: "Studio Martin",
    builder: "PD Projects",
    photography: null,
    styling: null,
  },
  {
    path: "tyabb_house",
    title: "Tyabb House",
    stage: "2020",
    description:
      "The renovation and addition to a house on a country property. Replanning within the existing footprint and adding volume to connect all spaces to nature.",
    images: [
      "tyabb_house_01.jpg",
      "tyabb_house_02.jpg",
      "tyabb_house_03.jpg",
      "tyabb_house_04.jpg",
      "tyabb_house_05.jpg",
      "tyabb_house_06.jpg",
      "tyabb_house_07.jpg",
      "tyabb_house_08.jpg",
    ],
    type: "Renovation & Addition",
    architecture: "Studio Martin",
    interiorDesign: "Studio Martin",
    builder: "Bluline Projects",
    photography: "Albert Chandra",
    styling: null,
  },
  {
    path: "caulfield_north_house",
    title: "Caulfield North House",
    stage: "2019",
    description:
      "The brief was to create a soft, calm space with clean lines that would offer a sense of sanctuary from the hustle and bustle of the everyday. Working within the existing framework of the house – which comprised an open-plan living, dining and kitchen; a separate study, lounge area, powder room and mudroom; and four bedrooms each with ensuites, and a kid’s retreat upstairs – we focused on carrying a considered and natural material palette throughout the home to create a warm and cohesive journey.",
    images: [
      "caulfield_north_house_00.jpg",
      "caulfield_north_house_01.jpg",
      "caulfield_north_house_02.jpg",
      "caulfield_north_house_03.jpg",
      "caulfield_north_house_04.jpg",
      "caulfield_north_house_05.jpg",
      "caulfield_north_house_06.jpg",
      "caulfield_north_house_07.jpg",
      "caulfield_north_house_08.jpg",
      "caulfield_north_house_09.jpg",
      "caulfield_north_house_10.jpg",
      "caulfield_north_house_11.jpg",
      "caulfield_north_house_12.jpg",
      "caulfield_north_house_13.jpg",
      "caulfield_north_house_14.jpg",
      "caulfield_north_house_15.jpg",
      "caulfield_north_house_16.jpg",
      "caulfield_north_house_17.jpg",
      "caulfield_north_house_18.jpg",
      "caulfield_north_house_19.jpg",
    ],
    type: "Renovation",
    architecture: "Wald Architects",
    interiorDesign: "Studio Martin",
    builder: "Platinum Construction",
    photography: "Martina Gemmola",
    styling: "Jarvis Barker",
  },
  {
    path: "bentleigh_east_townhouses_1",
    title: "Bentleigh East Townhouses",
    stage: "2021",
    description:
      "A new build consisting of two three-bedroom dwellings of north-facing frontage. The Townhouses had a limited construction budget. The homes were crafted to accommodate a range of individual tastes and lifestyles so that prospective buyers may easily envision inhabiting the property.",
    images: [
      "bentleigh_east_townhouses_I_01.jpg",
      "bentleigh_east_townhouses_I_02.jpg",
      "bentleigh_east_townhouses_I_03.jpg",
      "bentleigh_east_townhouses_I_04.jpg",
      "bentleigh_east_townhouses_I_05.jpg",
      "bentleigh_east_townhouses_I_06.jpg",
      "bentleigh_east_townhouses_I_07.jpg",
      "bentleigh_east_townhouses_I_08.jpg",
      "bentleigh_east_townhouses_I_09.jpg",
      "bentleigh_east_townhouses_I_10.jpg",
      "bentleigh_east_townhouses_I_11.jpg",
      "bentleigh_east_townhouses_I_12.jpg",
      "bentleigh_east_townhouses_I_13.jpg",
      "bentleigh_east_townhouses_I_14.jpg",
      "bentleigh_east_townhouses_I_15.jpg",
      "bentleigh_east_townhouses_I_16.jpg",
      "bentleigh_east_townhouses_I_17.jpg",
      "bentleigh_east_townhouses_I_18.jpg",
      "bentleigh_east_townhouses_I_19.jpg",
      "bentleigh_east_townhouses_I_20.jpg",
    ],
    type: "New Build",
    architecture: "Studio Martin",
    interiorDesign: "Studio Martin",
    builder: "Platinum Construction",
    photography: "Martina Gemmola",
    styling: "Jarvis Barker",
  },
  {
    path: "beaumaris_unit_2",
    title: "Beaumaris Unit",
    stage: "2020",
    awards: ["2020 Houses Awards Shortlist, Apartment or Unit"],
    description:
      "The task to update a granny flat; a past typical suburban development model - one of three Units on a block. The goal was to refurbish the whole unit with minimal structural intervention, whilst creating connections to the outdoors, bringing in natural light and achieving an open plan flow.",
    images: [
      "beaumaris_unit_II_01.jpg",
      "beaumaris_unit_II_02.jpg",
      "beaumaris_unit_II_03.jpg",
      "beaumaris_unit_II_04.jpg",
      "beaumaris_unit_II_05.jpg",
      "beaumaris_unit_II_06.jpg",
      "beaumaris_unit_II_07.jpg",
      "beaumaris_unit_II_08.jpg",
      "beaumaris_unit_II_09.jpg",
      "beaumaris_unit_II_10.jpg",
      "beaumaris_unit_II_11.jpg",
      "beaumaris_unit_II_12.jpg",
      "beaumaris_unit_II_13.jpg",
      "beaumaris_unit_II_14.jpg",
      "beaumaris_unit_II_15.jpg",
      "beaumaris_unit_II_16.jpg",
      "beaumaris_unit_II_17.jpg",
      "beaumaris_unit_II_18.jpg",
      "beaumaris_unit_II_19.jpg",
      "beaumaris_unit_II_20.jpg",
      "beaumaris_unit_II_21.jpg",
      "beaumaris_unit_II_22.jpg",
      "beaumaris_unit_II_23.jpg",
    ],
    type: "Renovation",
    architecture: "Studio Martin",
    interiorDesign: "Studio Martin",
    builder: "Bluline Projects",
    photography: "Tom Blachford",
    styling: "Jarvis Barker",
  },
  {
    path: "alphington_house",
    title: "Alphington House",
    stage: "Construction Stage",
    description: "A new build home on the edge of the Darebin Parklands.",
    images: ["alphington_house_01.jpg"],
    type: "New Build",
    architecture: "Studio Martin",
    interiorDesign: "Studio Martin",
    builder: "Bluline Projects",
    photography: null,
    styling: null,
  },
  {
    path: "brighton_townhouses",
    title: "Brighton Townhouses",
    stage: "2023",
    description:
      "A new build in leafy Bayside Brighton, consisting of two four-bedroom dwellings, over three levels, with lift access. Basement carpark with Gym, Wine Cellar, Laundry and Rumpus Room. Ground Floor with second main bedroom with ensuite, powder room, study, kitchen, butler's pantry, dining and living zone, opening to outdoor entertaining and pool. First Floor with living zone, two bedrooms with ensuites and main bedroom suite with WIR and ensuite.",
    images: [
      "brighton_townhouses_01.jpg",
      "brighton_townhouses_02.jpg",
      "brighton_townhouses_03.jpg",
    ],
    type: "New Build",
    architecture: "Studio Martin",
    interiorDesign: "Studio Martin",
    builder: "Platinum Builder",
    photography: null,
    styling: null,
  },
  {
    path: "beaumaris_unit_1",
    title: "Beaumaris Kitchen",
    stage: "2017",
    description:
      "A new kitchen and laundry renovation, with a fresh palette and modern approach, to breathe life into an existing 1950's unit.",
    images: [
      "beaumaris_unit_I_01.jpg",
      "beaumaris_unit_I_02.jpg",
      "beaumaris_unit_I_03.jpg",
      "beaumaris_unit_I_04.jpg",
      "beaumaris_unit_I_05.jpg",
      "beaumaris_unit_I_06.jpg",
      "beaumaris_unit_I_07.jpg",
      "beaumaris_unit_I_08.jpg",
    ],
    type: "Renovation",
    architecture: null,
    interiorDesign: "Studio Martin",
    builder: null,
    photography: "Albert Chandra",
    styling: null,
  },
  {
    path: "santana_bar",
    title: "Santana Bar",
    stage: "2024",
    description:
      "Santana rooftop bar, located in Melbourne CBD. We crafted the design for this venue with a Latin-inspired concept in mind. Embracing moody hues, red neon lights, 1940's black-and-white film and the smoky haze from cigars, we sought to create an overall sultry vibe. We aimed to immerse every visitor in a captivating ambiance reminiscent of their own Havana night.",
    images: [
      "santana_bar_01.jpg",
      "santana_bar_02.jpg",
      "santana_bar_03.jpg",
      "santana_bar_04.jpg",
      "santana_bar_05.jpg",
      "santana_bar_06.jpg",
      "santana_bar_07.jpg",
      "santana_bar_08.jpg",
      "santana_bar_09.jpg",
      "santana_bar_10.jpg",
      "santana_bar_11.jpg",
      "santana_bar_12.jpg",
      "santana_bar_13.jpg",
      "santana_bar_14.jpg",
      "santana_bar_15.jpg",
    ],
    type: "Hospitality",
    architecture: null,
    interiorDesign: "Studio Martin",
    builder: null,
    photography: "Longboy Media",
    styling: null,
  },
  {
    path: "bentleigh_east_townhouses_2",
    title: "Bentleigh East Townhouses II",
    stage: "2023",
    description:
      "Completing just the interior design for one Townhouse. Pairing back the materials to create a home that was light, bright and a little cheerful for a family of four.",
    images: [
      "bentleigh_east_townhouses_II_01.jpg",
      "bentleigh_east_townhouses_II_02.jpg",
      "bentleigh_east_townhouses_II_03.jpg",
      "bentleigh_east_townhouses_II_04.jpg",
      "bentleigh_east_townhouses_II_05.jpg",
      "bentleigh_east_townhouses_II_06.jpg",
    ],
    type: "Interiors",
    architecture: "KG Architects",
    interiorDesign: "Studio Martin",
    builder: "Unique Homes",
    photography: "Realestate",
    styling: null,
  },
  {
    path: "trentham_house",
    title: "Trentham House",
    stage: "Concept Stage",
    description:
      "Located in the countryside of the quaint Trentham, lies the renovation and addition to this Country home.",
    images: ["trentham_house_01.jpg"],
    type: "Renovation & Addition",
    architecture: "Studio Martin",
    interiorDesign: "Studio Martin",
    builder: null,
    photography: null,
    styling: null,
  },
  {
    path: "obrien_real_estate",
    title: "OBrien Real Estate",
    stage: "2021",
    description:
      "New reception and waiting area for an OBrien Real Estate office. Creating a space that feels welcoming and homely for prospective clients or buyers.",
    images: [
      "obrien_real_estate_01.jpg",
      "obrien_real_estate_02.jpg",
      "obrien_real_estate_03.jpg",
      "obrien_real_estate_04.jpg",
      "obrien_real_estate_05.jpg",
      "obrien_real_estate_06.jpg",
    ],
    type: "Office Fit-out",
    architecture: null,
    interiorDesign: "Studio Martin",
    builder: null,
    photography: "Martina Gemmola",
    styling: "Jarvis Barker",
  },
  {
    path: "red_hill_house",
    title: "Red Hill House",
    stage: "Construction Stage",
    description:
      "Entire house renovation and internal rework of spaces. Working within the existing envelope of the home, celebrating and enhancing the existing vaulted ceiling.",
    images: ["red_hill_house_01.jpg"],
    type: "Renovation",
    architecture: null,
    interiorDesign: "Studio Martin",
    builder: "Bluline Projects",
    photography: null,
    styling: null,
  },
  {
    path: "stali_canteen",
    title: "ST.ALi Kiosk",
    stage: "Concept Stage",
    description:
      "Located at Monash University Clayton, a new coffee and takeaway outlet for ST.ALi, on a prominent corner store location on campus.",
    images: ["stali_canteen_01.jpg"],
    type: "Hospitality Fit-out",
    architecture: null,
    interiorDesign: "Studio Martin",
    builder: null,
    photography: null,
    styling: null,
  },
  {
    path: "excelsior_building",
    title: "Excelsior Building, CBD",
    stage: "Construction Stage",
    description:
      "The building, originally constructed circa 1939 and converted to Apartments in 1995, has had many additions and alterations over the years. Stage 1 involved refreshing the building lobbies. The approach drew from, and remain sympathetic to, the original features. Stage 2 involves refreshing the whole building facade.",
    images: ["excelsior_building_01.jpg"],
    type: "Renovation",
    orginalArchitecture: "Cedric Ballantyne",
    architecture: "Studio Martin",
    interiorDesign: "Studio Martin in collaboration with Maher Studio",
    builder: null,
    photography: null,
    styling: null,
  },
  {
    path: "tony_tan_cooking_school",
    title: "Tony Tan Cooking School",
    stage: "2020",
    description:
      "Farm to table cooking school, in the Victorian town of Trentham. Renovating a 1990s-built brick-veneer house into a modern, light-filled home and kitchen where Tony creates his magic at his stone altar.",
    images: [
      "tony_tan_cooking_school_01.jpg",
      "tony_tan_cooking_school_02.jpg",
      "tony_tan_cooking_school_03.jpg",
      "tony_tan_cooking_school_04.jpg",
      "tony_tan_cooking_school_05.jpg",
      "tony_tan_cooking_school_06.jpg",
      "tony_tan_cooking_school_07.jpg",
      "tony_tan_cooking_school_08.jpg",
      "tony_tan_cooking_school_09.jpg",
      "tony_tan_cooking_school_10.jpg",
      "tony_tan_cooking_school_11.jpg",
      "tony_tan_cooking_school_12.jpg",
      "tony_tan_cooking_school_13.jpg",
    ],
    type: "Renovation & Addition",
    architecture: "Studio Martin",
    interiorDesign: "Studio Martin",
    builder: "Lachlan Fleming Constructions",
    photography: "Kristoffer Paulsen",
    styling: null,
  },
];

export default projects;
