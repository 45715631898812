import "./App.css";
import Menu from "./Menu";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Cover from "./pages/Cover";
import { useState, useEffect } from "react";
import useIsPC from "./hooks/useIsPC";
import useIsAndroid from "./hooks/useIsAndroid";
import { useLocation } from "react-router-dom";
import { useScroll } from "framer-motion";

function App() {
  const isPC = useIsPC();
  const osClass = isPC ? "pc" : "mac";
  const [showCover, setShowCover] = useState(true);
  const isAndroid = useIsAndroid();
  const { pathname } = useLocation();
  const [headerTransparentClass, setHeaderTransparentClass] = useState("");
  const { scrollY } = useScroll();

  useEffect(() => {
    const unsubscribeY = scrollY.on("change", y => {
      if (pathname === "/") {
        if (y > 175) {
          setHeaderTransparentClass("transparent");
        } else {
          setHeaderTransparentClass("");
        }
      } else {
        setHeaderTransparentClass("");
      }
    });

    return () => {
      unsubscribeY();
    };
  }, [pathname]);

  return (
    <div className={`App ${osClass} ${isAndroid ? "android" : ""}`}>
      <h1 className={`main-title ${headerTransparentClass}`}>STUDIO MARTIN</h1>
      <Menu />
      <div className="page-container">
        <Outlet />
      </div>
      <Footer />
      <Cover show={showCover} onClick={() => setShowCover(!showCover)} />
    </div>
  );
}

export default App;
