import './Footer.css'
import logo from './images/logo.png'
import instagram from './images/instagram_logo.png'
import linkedin from './images/linkedin_logo.png'

// if logo is already on projects page, go to top of pageP
const Footer = () => (
  <div className='footer'>
    <div onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}> 
      <img height="25" src={logo} alt="logo" />
    </div>
    <a href="https://www.instagram.com/studiomartin_" target="_blank" rel="noreferrer">
      <img height="25" src={instagram} alt="instagram" />
    </a>
    <a href="https://www.linkedin.com/company/studiomartin22" target="_blank" rel="noreferrer">
      <img height="25" src={linkedin} alt="linkedin" />
    </a>
  </div>
)

export default Footer