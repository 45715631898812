export const press = [
  {
    date: "2024",
    publication: "Timeout",
    article: "A Sultry Bar",
    project: "Santana Bar",
    link: "https://www.timeout.com/melbourne/news/a-sultry-new-latin-inspired-rooftop-bar-in-melbourne-is-serving-up-cigars-cocktails-and-vino-041024",
    imagePreview: "28_timeout.jpg",
    type: "Online Article",
  },
  {
    date: "2023",
    publication: "The Local Project",
    article: "A Modest Projection",
    project: "Elwood House",
    link: "https://thelocalproject.com.au/articles/elwood-house-by-studio-martin-project-feature-the-local-project/",
    imagePreview: "27_the_local_project.jpg",
    type: "Online Article",
  },
  {
    date: "2022",
    publication: "Inside Out",
    article: "December Issue",
    project: "Elwood House",
    link: "/press/26a_inside_out.pdf",
    imagePreview: "26_inside_out.jpg",
    type: "Magazine",
  },
  {
    date: "2022",
    publication: "Good Food",
    article: "Good Food",
    project: "Tony Tan Cooking School",
    link: "https://www.theage.com.au/goodfood/melbourne-eating-out/tony-tan-teaching-from-the-heart-in-trentham-20210517-h1vv9i.html",
    imagePreview: "25_the_age.jpg",
    type: "Online Article",
  },
  {
    date: "2022",
    publication: "Real Living",
    article: "April Issue",
    project: "Bentleigh East Townhouses",
    link: "/press/24a_rl_april_studiomartin.pdf",
    imagePreview: "24_real_living.jpg",
    type: "Magazine",
  },
  {
    date: "2021",
    publication: "NY Times",
    article: "Tony Tan’s Chiu Chow",
    project: "Tony Tan Cooking School",
    link: "https://taustralia.com.au/tony-tans-chiu-chow-dumpling-recipe/",
    imagePreview: "23_ny_times.jpg",
    type: "Online Article",
  },
  {
    date: "2021",
    publication: "Financial Review",
    article: "Tony Tan gets spicy in Trentham",
    project: "Tony Tan Cooking School",
    link: "https://www.afr.com/life-and-luxury/food-and-wine/tony-tan-gets-spicy-in-trentham-20210825-p58lsy",
    imagePreview: "22_financial_review.jpg",
    type: "Online Article",
  },
  {
    date: "2021",
    publication: "Domain Review",
    article: "Domain Review",
    project: "Tony Tan Cooking School",
    link: "/press/21a_domain_review.pdf",
    imagePreview: "21_the_age.jpg",
    type: "Newspaper",
  },
  {
    date: "2021",
    publication: "Qantas Magazine",
    article: "Travel Insider",
    project: "Tony Tan Cooking School",
    link: "/press/20a_qantas.pdf",
    imagePreview: "20_qantas.jpg",
    type: "Magazine",
  },
  {
    date: "2021",
    publication: "Beta Plus",
    article: "Contemporary Houses & Interiors",
    project: "Caulfield North House",
    link: "https://www.amazon.com/Contemporary-Houses-Interiors-English-French/dp/2875500953",
    imagePreview: "19_book.jpg",
    type: "Book",
  },
  {
    date: "2021",
    publication: "The Local Project",
    article: "Innovative Solutions",
    project: "Bentleigh East Townhouses",
    link: "https://thelocalproject.com.au/articles/bentleigh-east-townhouses-by-studio-martin-project-feature-the-local-project/",
    imagePreview: "18_the_local_project.jpg",
    type: "Online Article",
  },
  {
    date: "2021",
    publication: "Broadsheet",
    article: "Food & Drink",
    project: "Tony Tan Cooking School",
    link: "https://www.broadsheet.com.au/melbourne/food-and-drink/article/his-home-picturesque-trentham-renowned-chef-and-author-tony-tan-raising-chooks-growing-pandan-and-opening-new-eight-person-cooking-school",
    imagePreview: "17_broadsheet.jpg",
    type: "Online Article",
  },
  {
    date: "2021",
    publication: "The Age",
    article: "May",
    project: "Tony Tan Cooking School",
    link: "/press/16a_TonyTan_The_Age_May_2021_sm.pdf",
    imagePreview: "16_the_age.jpg",
    type: "Newspaper",
  },
  {
    date: "2021",
    publication: "Design Anthology",
    article: "Australian Edition Issue 04",
    project: "Caulfield North House",
    link: "/press/15a_design_anthology.pdf",
    imagePreview: "15_design_anthology.jpg",
    type: "Magazine",
  },
  {
    date: "2021",
    publication: "Houses",
    article: "Issue 140, One to Watch",
    project: "Studio Martin",
    link: "/press/14a_one_to_watch_studiomartin.pdf",
    imagePreview: "14_houses.jpg",
    type: "Magazine",
  },
  {
    date: "2020",
    publication: "The Local Project",
    article: "A Contemporary Reinvention",
    project: "Beaumaris Unit",
    link: "https://thelocalproject.com.au/articles/glenwood-unit-by-studio-martin-project-feature-the-local-project/",
    imagePreview: "13_the_local_project.jpg",
    type: "Online Article",
  },
  {
    date: "2020",
    publication: "Signorino",
    article: "Projects Residential",
    project: "Beaumaris Kitchen",
    link: "https://www.signorino.com.au/projects/beaumaris-residence/",
    imagePreview: "12_signorino.jpg",
    type: "Online Article",
  },
  {
    date: "2020",
    publication: "Laminex",
    article: "Project Gallery",
    project: "Beaumaris Kitchen",
    link: "https://www.laminex.com.au/gallery/Beaumaris-Project-by-Studio-Martin-Kitchen-gallery",
    imagePreview: "11_laminex.jpg",
    type: "Online Article",
  },
  {
    date: "2020",
    publication: "Home-Style NZ",
    article: "Issue 98",
    project: "Beaumaris Unit",
    link: "/press/10a_HomeStyle_Issue98.pdf",
    imagePreview: "10_homestyle98.jpg",
    type: "Magazine",
  },
  {
    date: "2020",
    publication: "The Design Files",
    article: "Design Eye",
    project: "Studio Martin",
    link: "https://thedesignfiles.net/2020/10/studio-martin-on-refined-rattan/",
    imagePreview: "9_the_design_files.jpg",
    type: "Online Article",
  },
  {
    date: "2020",
    publication: "Home Beautiful",
    article: "Kitchen & Bathroom Issue",
    project: "Caulfield North House",
    link: "/press/8a_hb_kitchen_bathroom.pdf",
    imagePreview: "8_home_beautiful.jpg",
    type: "Magazine",
  },
  {
    date: "2020",
    publication: "Habitus Living",
    article: "An Off-Plan House Turned Sanctuary",
    project: "Caulfield North House",
    link: "https://www.habitusliving.com/architecture/caulfield-north-studio-martin",
    imagePreview: "7_habitus_living.jpg",
    type: "Online Article",
  },
  {
    date: "2020",
    publication: "Home Beautiful",
    article: "June Issue",
    project: "Caulfield North House",
    link: "/press/6a_home_beautiful.pdf",
    imagePreview: "6_home_beautiful.jpg",
    type: "Magazine",
  },
  {
    date: "2020",
    publication: "Interior Design NY",
    article: "Timber Carries Through",
    project: "Caulfield North House",
    link: "https://www.interiordesign.net/projects/18039-timber-carries-through-a-melbourne-apartment-by-studio-martin/",
    imagePreview: "5_interior_design.jpg",
    type: "Online Article",
  },
  {
    date: "2020",
    publication: "Houses Awards",
    article: "Shortlist, Apartment or Unit",
    project: "Beaumaris Unit",
    link: "https://housesawards.com.au/gallery/2020/apartment-or-unit/8Y0MBQVLNLM?category=apartment-or-unit&year=2020&location=VIC&recognition=3",
    imagePreview: "4_houses_awards.jpg",
    type: "Awards",
  },
  {
    date: "2020",
    publication: "Home Beautiful",
    article: "March Issue",
    project: "Caulfield North House",
    link: "/press/3a_home_beautiful.pdf",
    imagePreview: "3_home_beautiful.jpg",
    type: "Magazine",
  },
  {
    date: "2019",
    publication: "Sika Design",
    article: "Handmade Furniture, Icons",
    project: "Caulfield North House",
    link: "/press/2a_sika_design.pdf",
    imagePreview: "2_sika_design.jpg",
    type: "Catalogue",
  },
  {
    date: "2017",
    publication: "Design Milk",
    article: "Instagram Feature",
    project: "Beaumaris Kitchen",
    link: "/press/1a_design_milk.pdf",
    imagePreview: "1_design_milk.jpg",
    type: "Instagram",
  },
];

export default press;
