import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import Projects from './pages/Projects'
import About from './pages/About'
import Services from './pages/Services'
import Press from './pages/Press'
import Contact from './pages/Contact'

import reportWebVitals from './reportWebVitals'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "about",
        element: <About />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "press",
        element: <Press />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "/",
        element: <Projects />,
      },
    ]
  },
])
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <RouterProvider router={ router } />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
