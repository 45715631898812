import "./About.css";
import about from "../images/about_photo.jpg";
import { motion } from "framer-motion";

const About = props => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 1 }}
    className="about-container"
  >
    <div>
      <p style={{ marginTop: 0 }}>
        Founded in 2018 by sisters, Lauren and Amanda, our studio offers full
        architectural and interior design services from concept design to site
        delivery. We undertake projects of varying size and work within a range
        of project typologies including multi-residential, residential new
        homes, renovations / additions, education, commercial and hospitality.
      </p>
      <p>
        Studio Martin create spaces that contribute to a better quality of life.
        For us it’s about a feeling, it’s about an experience, it’s about
        affecting your senses, it’s about leaving an impression on someone. This
        is how we build a project! Approaching every project with consideration,
        we collaborate with each other to provide an engaging process and
        outcome for our clients and their individual needs.
      </p>
    </div>
    <div>
      <img className="about-img" src={about} alt="about" />
    </div>
    <div className="last-col">
      <div>
        <div className="director-heading">
          <div>LAUREN MARTIN</div>
          <div>
            <b>DIRECTOR / ARCHITECT</b>
          </div>
          <div style={{ fontSize: 13 }}>
            <div>Bachelor of Architectural Design</div>
            <div>Master of Architecture (Hons)</div>
            <br />
            <div>Registered Architect (ARBV)</div>
            <div>Member of the Australian Institute of Architects (RAIA)</div>
          </div>
        </div>
        <p>
          Prior to co-establishing Studio Martin, Lauren worked at various sized
          architectural practices in Melbourne and has experience working with
          varied clients and types of projects including educational, health
          care, retail and single and multi-residential projects.
        </p>
        <p>
          Whilst completing her Master of Architecture at RMIT she studied
          abroad at the Royal Institute of Technology- KTH in Stockholm where
          she developed her interest in materialization and layering of spaces.
          She believes in the importance of a collaborative design process,
          working with the client to create an innovative solution with
          attention to detail.
        </p>
      </div>
      <div>
        <div className="director-heading">
          <div>AMANDA MARTIN</div>
          <div>
            <b>DIRECTOR / INTERIOR DESIGNER</b>
          </div>
          <div style={{ fontSize: 13 }}>
            <div>Bachelor of Interior Architecture (Hons)</div>
            <br />
            <div>
              Affiliate member of theAustralian Institute of Architects (RAIA)
            </div>
          </div>
        </div>
        <p>
          Amanda is an Interior Designer and Director of Studio Martin with over
          10 years’ experience. She has worked across multiple sectors and
          scales at a top tier architectural firm that has equipped her with the
          skills to design and deliver projects.
        </p>
        <p style={{ marginBottom: 0 }}>
          She has a passion for design and loves to discover new things in all
          aspects of design, art and object. Materiality plays a big part in how
          she approach’s design and the spatial experience and journey is key. A
          space should move you in some way and should be memorable that is very
          important.
        </p>
      </div>
    </div>
  </motion.div>
);

export default About;
