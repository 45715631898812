import './Contact.css'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { send } from '@emailjs/browser'
const Contact = props => {
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [description, setDescription] = useState('')
  const [budget, setBudget] = useState('')
  const [timeline, setTimeline] = useState('')
  const [source, setSource] = useState('')
  const [sentEmail, setSentEmail] = useState(false)
  const [sendingEmail, setSendingEmail] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setSendingEmail(true)
    send(
      'service_fzgvr49', 
      'template_41k2avc', 
      { 
        name,
        number,
        email,
        address,
        description,
        budget,
        timeline,
        source,
      },
      { publicKey: 'XkXKRncnbF7QHKlkA' }
    ).then(
      (response) => {
        setSentEmail(true)
        setSendingEmail(false)
      },
      (err) => {
        console.error('FAILED TO SEND EMAIL: ', err)
        setSendingEmail(false)
      },
    )
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      className="contact-container">
      <div>
        <div style={{ marginTop: 0 }}className="form-heading">GENERAL ENQUIRIES</div>
        <p>
          If you have a project you would like to discuss, please fill out our project enquiry form. For any other enquiries, get in touch below.
        </p>
        <p>
        <p>
          <a style={{ color: 'black' }} href="mailto:contact@studiomartin.com.au">contact@studiomartin.com.au</a>
        </p>
        <div>
          Lauren - 0418911504
        </div>
        <div>
          Amanda - 0400788400
        </div>
        </p>
        <p>
          Our office - 9 Mckillop Street,
          <div>
            Melbourne VIC 3000
          </div>
        </p>
      </div>
      <div>
        <div className="form-heading">PROJECT ENQUIRY</div>
        {sentEmail ? (
          <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}>
            <div>
              Thank you for your enquiry. We will be in touch shortly.
            </div>
          </motion.div>
        ) : (
          <form onSubmit={handleSubmit}>
          <div>
            <input required onChange={ e => setName(e.target.value)} type="text" name="name" placeholder="Name" />
          </div>
          <div>
            <input required onChange={ e => setNumber(e.target.value)} type="tel" name="number" placeholder="Number" />
          </div>
          <div>
            <input required onChange={ e => setEmail(e.target.value)} type="email" name="email" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" placeholder="Email" />
          </div>
          <div>
            <input required onChange={ e => setAddress(e.target.value)} type="text" name="address" placeholder="Project address" />
          </div>
          <div>
            <input required onChange={ e => setDescription(e.target.value)} type="text" name="description" placeholder="Briefly describe the project" />
          </div>
          <div>
            <select required onChange={ e => setBudget(e.target.value)} name="budget">
              <option disabled selected>Anticipated budget</option>
              <option value="0-$500k">0 - $500k</option>
              <option value="$500k - $750k">$500k - $750k</option>
              <option value="$750k - $1Mil">$750k - $1Mil</option>
              <option value="$1Mil - $2Mil">$1Mil - $2Mil</option>
              <option value="$2Mil+">$2Mil+</option>
            </select>
          </div>
          <div>
            <input required onChange={ e => setTimeline(e.target.value)} type="text" name="timeline" placeholder="Desired timeline" />
          </div>
          <div>
            <input required onChange={ e => setSource(e.target.value)} type="text" name="source" placeholder="How did you hear about us?" />
          </div>
          <div>
            <input required className={`${sendingEmail ? 'sending-email' : ''}`} disabled={sendingEmail} type="submit" value={sendingEmail ? 'Sending...' : 'Submit'} />
          </div>
        </form>
      )}
      </div>
      <div />
    </motion.div>
  )
}

export default Contact