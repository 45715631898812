import { useState, useEffect } from "react"

export default function useIsAndroid() {
    const [isAndroid, setIsAndroid]= useState(false)
    
    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase()
        setIsAndroid(/android/g.test(userAgent))
    },[])
    return isAndroid
}