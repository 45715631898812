import "./Press.css";
import { press } from "../data/press";
import { useState } from "react";
import { motion } from "framer-motion";
import useIsMobile from "../hooks/useIsMobileDevice";

const Press = props => {
  const isMobile = useIsMobile();
  const [article, setArticle] = useState(null);
  const articlesByYear = {
    2024: [],
    2023: [],
    2022: [],
    2021: [],
    2020: [],
    2019: [],
    2017: [],
  };

  press.forEach(article => {
    articlesByYear[parseInt(article.date)].push(article);
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      {isMobile ? (
        <div className="press-container">
          {Object.keys(articlesByYear)
            .reverse()
            .map((year, i) => (
              <div>
                <div style={{ marginBottom: 5, marginTop: i === 0 ? 0 : 15 }}>
                  {year}
                </div>
                {articlesByYear[year].map(article => (
                  <div
                    onClick={() => article.link && window.open(article.link)}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    className="row"
                  >
                    <div class="publication">
                      <p>{article.publication}</p>
                    </div>
                    <div class="article">
                      <p>{article.project}</p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      ) : (
        <div className="press-container">
          <div className="press-items">
            <div className="row header" style={{ marginBottom: 10 }}>
              <div class="article header">
                <p>TITLE & PROJECT</p>
              </div>
              <div class="date header">
                <p>YEAR</p>
              </div>
              <div class="publication header">
                <p>PUBLISHER</p>
              </div>
              <div class="publication header">
                <p>TYPE</p>
              </div>
            </div>
            {press.map(press => (
              <div
                onClick={() => press.link && window.open(press.link)}
                onMouseEnter={() => setArticle(press)}
                onMouseLeave={() => setArticle(null)}
                className="row"
              >
                <div class="article">
                  <p>
                    {press.article} - <i>{press.project}</i>
                  </p>
                </div>
                <div class="date">
                  <p>{press.date}</p>
                </div>
                <div class="publication">
                  <p>{press.publication}</p>
                </div>
                <div class="publication">
                  <p>{press.type}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="press-image">
            <div className="press-image-container">
              {article?.imagePreview && (
                <img
                  src={`/press_images/${article.imagePreview}`}
                  alt="placeholder"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Press;
