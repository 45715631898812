import "./Menu.css";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Menu = params => {
  const { pathname } = useLocation();

  return (
    <div className="menu-container">
      <div className={`menu-item projects ${pathname === "/" ? "active" : ""}`}>
        <NavLink to="/">Projects</NavLink>
      </div>
      <div
        className={`menu-item about ${
          pathname.includes("/about") ? "active" : ""
        }`}
      >
        <NavLink to="about">About</NavLink>
      </div>
      <div
        className={`menu-item services ${
          pathname.includes("/services") ? "active" : ""
        }`}
      >
        <NavLink to="services">Services</NavLink>
      </div>
      <div
        className={`menu-item press ${
          pathname.includes("/press") ? "active" : ""
        }`}
      >
        <NavLink to="press">Press</NavLink>
      </div>
      <div
        className={`menu-item contact ${
          pathname.includes("/contact") ? "active" : ""
        }`}
      >
        <NavLink to="contact">Contact</NavLink>
      </div>
    </div>
  );
};
export default Menu;
