import "./Cover.css";
import logo from "../images/logo.png";
import { useState, useEffect } from "react";
import usePrevious from "../hooks/usePrevious";
import useIsMobile from "../hooks/useIsMobileDevice";
import { disableScroll, enableScroll } from "../helper.js";
import { motion } from "framer-motion";

const Cover = ({ show, onClick, delay = 500 }) => {
  const shownZIndex = 1000;
  const hiddenZIndex = -1000;
  const prev = usePrevious(show);
  const [zIndex, setZIndex] = useState(show ? shownZIndex : hiddenZIndex);
  const [hiddenClass, setHiddenClass] = useState("");
  const [animating, setAnimating] = useState(false);
  const reshow = show && !prev;
  const isMobile = useIsMobile();

  useEffect(() => {
    if (hiddenClass === "") {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [hiddenClass]);

  useEffect(() => {
    setTimeout(() => {
      if (isMobile) {
        setHiddenClass("hidden");
        setTimeout(() => {
          setZIndex(hiddenZIndex);
          setAnimating(false);
        }, delay);
      }
    }, 3000);
  });

  useEffect(() => {
    setAnimating(true);
    if (reshow) {
      setZIndex(shownZIndex);
      setTimeout(() => {
        setHiddenClass("");
        setAnimating(false);
      }, delay);
    } else {
      if (show) {
        setHiddenClass("");
        setZIndex(shownZIndex);
      } else {
        setHiddenClass("hidden");
        setTimeout(() => {
          setZIndex(hiddenZIndex);
          setAnimating(false);
        }, delay);
      }
    }
  }, [reshow, show, hiddenZIndex, delay]);

  return (
    <div
      onClick={show && !animating ? onClick : () => {}}
      style={{ zIndex }}
      className={`cover-container ${hiddenClass}`}
    >
      <img className="logo" src={logo} alt="logo" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className="text"
      >
        ARCHITECTURE & INTERIORS
      </motion.div>
    </div>
  );
};

export default Cover;
