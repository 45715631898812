import "./Services.css";
import { motion } from "framer-motion";
const Services = props => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 1 }}
    className="services-container"
  >
    <div>
      <div style={{ marginTop: 0 }} className="form-heading">
        SERVICES
      </div>
      <p style={{ maxWidth: "90%", fontSize: 16 }}>
        Studio Martin work across Architecture, Interior Design and
        Masterplanning. We offer a complete range of services and tailor the
        process to suit the individual projects needs.
      </p>
      <div>
        <div className="process-header">Our core services include:</div>
        <ul>
          <li>- Architecture</li>
          <li>- Interior Design</li>
          <li>- Furniture Procurement</li>
          <li>- Feasibility</li>
          <li>- Concept/Schematic Design</li>
          <li>- Design Development</li>
          <li>- Town Planning</li>
          <li>- Consultant Management</li>
          <li>- Construction Documentation</li>
          <li>- Contract Procurement & Tendering</li>
          <li>- Contract Administration</li>
        </ul>
      </div>
    </div>
    <div>
      <div style={{ marginTop: 0 }} className="form-heading">
        PROCESS
      </div>
      <div>
        <div className="process-header">Pre-Design Services:</div>
        <div>
          - Initial Meeting, Planning Analysis, Fee Proposal, Site
          Investigations, Existing Conditions
        </div>
        <div className="process-header">Concept Design:</div>
        <div>- Design Briefing, Concept Design, Concept Development</div>
        <div className="process-header">Town Planning (If required):</div>
        <div>
          - Town Planning Drawings, Assist with Town Planning Lodgement, Town
          Planning Process, Planning Permit Approval
        </div>
        <div className="process-header">Design Development:</div>
        <div>- Design Development Drawings, Consultant Engagement</div>
        <div className="process-header">Construction Documentation:</div>
        <div>
          - Construction Drawings, Integration of Consultant Documentation,
          Building Surveyor Lodgement, Building Permit Approval
        </div>
        <div className="process-header">Contractor Selection:</div>
        <div>
          - Tender (If required), Negotiated Offer (Preferred Builder), Commence
          Construction
        </div>
        <div className="process-header">
          Construction Administration (Project Support):
        </div>
        <div>
          - Site Inspections for Quality Control, Providing Additional
          Information, Provide Client with Regular Updates, Assess Progress
          Claims, Prepare Defects, Project Completion
        </div>
        <div></div>
      </div>
      <div></div>
    </div>
    <div></div>
  </motion.div>
);

export default Services;
